import React from "react";
import dayjs from 'dayjs';
import "./TaskNumbers.css";

function TaskNumbers(props) {
  const {
    dueOn
  } = props;

  return (
    <div className="navbar">
      <div className="navbar-link-due inter-light-black-10px">Due On</div>
      <div className="navbar-link-date">{dueOn ? dayjs(dueOn).format('MM/DD/YYYY') : 'TBD'}</div>
    </div>
  );
}

export default TaskNumbers;
