// Firebase Initialization
import * as fb from "firebase/app";
import {
  fetchSignInMethodsForEmail,
  getAuth,
  onAuthStateChanged,
  // sendSignInLinkToEmail,
  signInWithEmailLink,
  signOut
} from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { stringify } from 'qs';

const firebaseConfig = {
  apiKey: "AIzaSyBOa6YDRZECLDReOUrDkp4qpNc9yXqdoO0",
  authDomain: "nutshell-cf132.firebaseapp.com",
  projectId: "nutshell-cf132",
  storageBucket: "nutshell-cf132.appspot.com",
  messagingSenderId: "34199514204",
  appId: "1:34199514204:web:21c54ad7b5be5dcdc90a99",
  measurementId: "G-CL2DE0P5BE"
};

// Initialize Firebase
const app = fb.initializeApp(firebaseConfig);
const auth = getAuth(app);
const functions = getFunctions(app);

export const sendAuthLink = async ({ email }) => {
  const methods = await fetchSignInMethodsForEmail(auth, email);

  if (!methods.length) throw new Error('There is no account with this email.');

  const url = `${window.location.href}?${stringify({ email })}`;

  return httpsCallable(functions, 'sendSignInLink')({ email, url });
};

export const signInByAuthLink = async ({ email, link }) => signInWithEmailLink(auth, email, link);

export const logout = async () => signOut(auth);

export const onAuthStateChange = async cb => onAuthStateChanged(auth, cb);
