import React from "react";
import "./ProjectHeaderStatus.css";

function ProjectHeaderStatus(props) {
  const { children, color } = props;

  return (
    <div className="project-header-status">
      <div className="text-status" style={{ color: color }}>{children}</div>
    </div>
  );
}

export default ProjectHeaderStatus;
