import { useReducer } from 'react';

const init = {
  user: null,
  attempted: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        user: action.payload,
        attempted: true,
      };
    case 'LOGOUT':
      return {
        ...state,
        user: null,
        attempted: false,
      };
    default:
      throw new Error(`Invalid action type: ${action.type}`);
  }
};

const useAuthReducer = () => useReducer(reducer, init);

export default useAuthReducer;