import { createContext, useEffect } from "react";
import { signInByAuthLink, logout, onAuthStateChange } from "../api/firebase.service";
import useAuthReducer from "../reducers/auth.reducer";

export const AuthContext = createContext({
  user: null,
  login: () => null,
  logout: () => null,
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useAuthReducer();

  useEffect(() => {
    onAuthStateChange(user => {
      dispatch({ type: 'LOGIN', payload: user });
    });
  }, []);

  const value = {
    ...state,
    login: async ({ email, link }) => {
      await signInByAuthLink({ email, link });
    },
    logout: async () => {
      await logout();

      dispatch({ type: 'LOGOUT' });
    }
  };

  return <AuthContext.Provider value={value}>
    {children}
  </AuthContext.Provider>;
};