import React from "react";
import DueDate from "../DueDate";
import Completed from "../Completed";
import "./LeftFrame.css";
import dayjs from "dayjs";

function LeftFrame(props) {
  const { dueDate, completedAt } = props;

  return (
    <div className="left-frame">
      <DueDate date={dueDate ? dayjs(dueDate).format('MM-DD-YY') : ''} />
      <Completed date={completedAt ? dayjs(completedAt).format('MM-DD-YY') : ''} />
    </div>
  );
}

export default LeftFrame;
