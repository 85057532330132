import React, { useEffect, useState } from "react";
import anime from 'animejs';
import "./VarbarFrame.css";
import { useContext } from "react";
import { LoadContext } from "../../providers/load.provider";

const VarbarFrame = ({ progress }) => {
  const [ref, setRef] = useState(null);
  const { loading } = useContext(LoadContext);

  useEffect(() => {
    if (!ref) return;

    if (!loading) {
      setTimeout(() => anime({
        targets: ref,
        easing: 'easeOutQuad',
        duration: 3000,
        backgroundPosition: ['0%', `${progress * 100}%`],
        width: ['0%', `${progress * 100}%`]
      }), 1000);
    }
  }, [progress, loading, ref]);

  return (
    <div className="varbar-frame">
      <div className="frame-varbar-bg">
        <div ref={setRef} style={{ width: 0 }} className="frame-varbar-gradient"></div>
      </div>
    </div>
  );
}

export default VarbarFrame;
