import React from 'react';
import './Footer.css';

const Footer = ({ logo }) => {
  return (
    <div className='footer'>
      {/* <img className='logo' src={logo} /> */}
      <p className='created-by-noodle-group-llc-with valign-text-bottom'>Created with 🍜 by <a href='https://noodlegroup.com/'>Noodle Group LLC</a></p>
    </div>
  );
}

export default Footer;
