import { createContext } from "react";
import useLoadReducer from "../reducers/load.reducer";

export const LoadContext = createContext({
  loading: false,
});

export const LoadProvider = ({ children }) => {
  const [state, dispatch] = useLoadReducer();

  const value = {
    ...state,
    startLoading: () => dispatch({ type: 'LOADING' }),
    doneLoading: () => dispatch({ type: 'LOADED' }),
  };

  return <LoadContext.Provider value={value}>
    {children}
  </LoadContext.Provider>;
};