import React from "react";
import "./TaskSummary.css";

function TaskSummary(props) {
  const { completed, completeddate } = props;

  return (
    <div className="task-summary">
      <div className="completed">{completed}</div>
      <div className="completed-date">{completeddate}</div>
    </div>
  );
}

export default TaskSummary;
