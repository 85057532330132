import Lottie from "../components/Lottie"
import anime from 'animejs';
import { useEffect } from "react";
import { useState } from "react";

const Loading = ({ loading, branded }) => {
  const [ref, setRef] = useState(null);
  const [closed, setClosed] = useState(false);
  const [complete, setComplete] = useState(false);

  useEffect(() => {
    if (!ref)
      return;

    if (loading) {
      setClosed(false);
      return;
    }

  }, [ref, loading]);

  useEffect(() => {
    if (!branded) {
      setClosed(true);
      setComplete(true);
    }

    if (!loading && complete && !closed)
      anime({
        targets: ref,
        opacity: [1, 0],
        duration: 500,
        easing: 'linear',
        complete: () => setClosed(true)
      });

  }, [ref, loading, complete, closed, branded]);

  return !(complete && closed) && (branded ? <div ref={setRef} style={{
    position: 'fixed',
    zIndex: 1000,
    display: 'block',
    width: '100vw',
    height: '100vh',
    backgroundColor: '#fff'
  }}>
    <div style={{ margin: '45vh 0', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Lottie onComplete={() => setComplete(true)} src={require('../static/lottie/lottie-noodle-group.json')} />
      <br />
      <div className="spinner-grow spinner-grow-sm center" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>

  </div> : null);
};

export default Loading;