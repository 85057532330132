import { useState, useEffect } from "react";
import anime from 'animejs';
import "./Project.css";

const Project = () => {
  const [ref, setRef] = useState(null);

  useEffect(() => {
    if (!ref) return;

    anime({
      targets: ref,
      easing: 'easeOutQuad',
      duration: 2500,
      backgroundPosition: [`100%`, '0%' ],
      loop: true
    });

  }, [ref]);
  return <div ref={setRef} className='project-loading' />;
};

export default Project;