import React from 'react';
import './Button.css';

const Button = ({ label, onClick, disabled, successfulFeedback, loading, success }) => {
  return <button
    onClick={onClick}
    className={`btn ${successfulFeedback && success ? 'btn-success' : 'btn-primary'} inter-medium-white-10px`}
    disabled={disabled || loading}
  >
    {/* <div style={{ display: 'inline-flex', justifyContent: 'center', alignContent: 'center' }}> */}
      <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }}>
        {label}
        <div style={{ paddingLeft: 5 }}>
        {successfulFeedback && loading && !success && <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
        {successfulFeedback && success && <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
          <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
        </svg>}
        </div>
      </div>
    {/* </div> */}
  </button>;
};

export default Button;
