import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './globals.css';
import './styleguide.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from './providers/auth.provider';
import { LoadProvider } from './providers/load.provider';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <LoadProvider>
      <AuthProvider>
        <Router>
          <App />
        </Router>
      </AuthProvider>
    </LoadProvider>
  </React.StrictMode>
);
