import React from "react";
import "./DueDate.css";

function DueDate(props) {
  const { date } = props;

  return (
    <div className="due-date">
      <div className="frame-static-1">
        <img alt="" className="vector" src={require('../../static/img/vector@2x.png')} />
        <div className="due-date-1 inter-bold-fuscous-gray-10px">Due Date</div>
      </div>
      <div className="date inter-normal-delta-10px">{date}</div>
    </div>
  );
}

export default DueDate;
