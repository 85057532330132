import React from "react";
import "./Completed.css";

function Completed(props) {
  const { date } = props;

  return (
    <div className="completed-1">
      <div className="frame-static-2">
        <img alt="" className="calendar2-check-fill" src={require('../../static/img/calendar2checkfill@2x.png')} />
        <div className="completed-2 inter-bold-fuscous-gray-10px">Completed</div>
      </div>
      <div className="date-1 inter-normal-delta-10px">{date}</div>
    </div>
  );
}

export default Completed;
