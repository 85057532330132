import React from "react";
import NotesFrame from "../NotesFrame";
// import BottomFrame from "../BottomFrame";
import "./RightBin.css";

function RightBin(props) {
  const { notes } = props;

  return (
    <div className="right-bin">
      <NotesFrame
        paragraph={notes}
      />
      {/* <BottomFrame
        subTaskFrameProps={bottomFrameProps.subTaskFrameProps}
        attatchmentsFrameProps={bottomFrameProps.attatchmentsFrameProps}
      /> */}
    </div>
  );
}

export default RightBin;
