import { useContext } from "react";
import { LoadContext } from "../providers/load.provider";

export const useLoading = cb => {
  const { startLoading, doneLoading } = useContext(LoadContext);
  
  return async v => {

    startLoading();

    const r = await cb(v);

    doneLoading();

    return r;
  }
};