import React from "react";
import "./NotesFrame.css";

function NotesFrame(props) {
  const { paragraph } = props;

  return (
    <div className="notes-frame border-1px-alto">
      <div className="headline-frame">
        <img alt="" className="file-text-fill" src={require('../../static/img/filetextfill@2x.png')} />
        <div className="notes inter-bold-fuscous-gray-10px">Notes</div>
      </div>
      <div className="frame-paragraph">
        <p className="paragraph inter-normal-delta-10px" style={!paragraph && { color: '#bbb'}} >{paragraph || 'Nothing has been entered at this time...'}</p>
      </div>
    </div>
  );
}

export default NotesFrame;
