import React from "react";
import ProjectHeaderStatus from "../ProjectHeaderStatus";
import "./ProjectHeader.css";

const colors = {
  blue: '#004CFF',
  red: '#E84855',
  green: '#21CC7E',
  yellow: '#FFE07E',
};

const statuses = {
  on_track: 'On Track',
  off_track: 'Off Track',
  on_hold: 'On Hold',
  at_risk: 'At Risk',
  complete: 'Complete',
};

function ProjectHeader(props) {
  const { name, status, color } = props;

  //   Blue - “On Hold” - 004CFF
  // Red - “Off Track” - E84855
  // Green - “On Track” - 21CC7E
  // Yellow - “At Risk” - FFE07E (edited



  return (
    <div className="project-header">
      <div className="text-project-name-1 inter-bold-black-30px">{name}</div>
      <ProjectHeaderStatus color={colors[color]}>{statuses[status]}</ProjectHeaderStatus>
      <div className="frame-status">
        <div className="shape-status" style={{ backgroundColor: colors[color] }}></div>
      </div>
    </div>
  );
}

export default ProjectHeader;
