import React from "react";
import TaskSummary from "../TaskSummary";
import "./InfoLine.css";

function InfoLine(props) {
  const { completed, name } = props;

  return (
    <div className="info-line">
      {completed ? <img alt="" className="info-line-item" src={require('../../static/img/checkicon@2x.png')} /> : <div style={{ width: 20, height: 20, borderRadius: 10, backgroundColor: '#dcdcdc' }}></div>}
      <p className="task-name">{name}</p>
      <TaskSummary
      completed={completed}
      />
      <img alt="" className="info-line-item" src={require('../../static/img/caretdownfill@2x.png')} />
    </div>
  );
}

export default InfoLine;
