import { useParams } from 'react-router-dom';
import Lottie from '../../components/Lottie';
import Task from '../../components/Task';
import { useProject } from '../../hooks/useProject.hook';
import './FocusedProject.css';

const FocusedProject = ({
  id,
  // name,
  // createdAt,
  // dueOn,
  // currentStatus: {
  //   status,
  //   color,
  //   title,
  //   description
  // },
  onClose
}) => {
  const params = useParams();

  const project = useProject({ id: params.id });

  return <div className='tasks screen' key={id} >
    {!project && <Lottie src={require('../../static/lottie/lottie-noodle-group-wheel.json')} />}
    {project && <div className='frame-tasks'>
      {/* <div style={{
        alignSelf: 'flex-start', textAlign: 'justify',
        fontFamily: 'Inter',
        fontSize: 'small',
        cursor: 'pointer',
      }} onClick={() => onClose()}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
        </svg> Back
      </div> */}
      {project && Object.keys(project.tasks).map(k => {
        return project.tasks[k].map(t => <Task key={t.id} {...t} />)
      })}
    </div>}
  </div>;
}

export default FocusedProject;