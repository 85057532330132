import { useState, useEffect } from 'react';
import { useProjects } from '../../hooks/useProjects.hook';
import Header from "../../components/Header";
import HeaderFiller from "../../components/HeaderFiller";
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Project from "../../components/Project";
import ProjectFiller from "../../components/ProjectFiller";
import FocusedProject from '../FocusedProject';
import "./Projects.css";

const Projects = () => {
  const [project, setProject] = useState(null);
  const { orgName, projects } = useProjects();
  const navigate = useNavigate();
  const location = useLocation();
  const [displayLocation, setDisplayLocation] = useState(location);
  const [transitionStage, setTransistionStage] = useState("fadeIn");

  useEffect(() => {
    if (location !== displayLocation) setTransistionStage("fadeOut");
  }, [location]);

  useEffect(() => {
    if (location !== displayLocation) setTransistionStage("fadeOut");
  }, [project]);

  return <div
    className={`projects screen`}>
    {projects.length ? <Header
      name={orgName}
      selectedProject={project}
      onClick={() => {
        setProject(null);
        navigate('/');
      }}
    /> : <HeaderFiller />}
    <div
      className={`projects ${transitionStage}`}
      onAnimationEnd={() => {
        if (transitionStage === "fadeOut") {
          setTransistionStage("fadeIn");
          setDisplayLocation(location);
        }
      }}
    >
      <Routes location={displayLocation}>
        <Route path='/' element={<>
          {projects.length ? projects?.map(p => <Project
            key={p.id}
            onClick={() => {
              setProject(p)
              navigate(p.id)
            }}
            {...p}
          />) : <>
            <ProjectFiller />
            <ProjectFiller />
            <ProjectFiller />
          </>}
        </>} />
        <Route
          path=':id'
          element={<FocusedProject
            {...project}
            onClose={() => {
              setProject(null);
              navigate('/');
            }} />}
        />
      </Routes>
    </div>
  </div>;
};

export default Projects;