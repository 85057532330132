import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import './Lottie.css';

const Lottie = ({ onComplete, src, ...rest }) => <div className='lottie'>
  <Player
    className='lottie'
    {...(onComplete && {
      onEvent: e => e === 'complete' && onComplete()
    })}
    src={src}
    background='transparent'
    speed='1'
    autoplay
    {...rest}
  ></Player>
</div>;

export default Lottie;
