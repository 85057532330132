import React from "react";
import FrameBreadcrumbs from "../FrameBreadcrumbs";
import "./FrameProjectName.css";

const FrameProjectName = ({ organizationName, selectedProject, onClick }) => {
  return (
    <div className="frame-project-name">
      <div className="text-company-name inter-bold-black-30px">{organizationName}</div>
      <FrameBreadcrumbs
        textbreadcrumbs1={'Updates'}
        textbreadcrumbs2={selectedProject ? ` > ${selectedProject.name}` : ''}
        onClick={onClick}
      />
    </div>
  );
}

export default FrameProjectName;
