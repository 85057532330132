import { useContext, useEffect, useState } from "react";
import { getProject } from "../api/nutshell.service";
import { AuthContext } from "../providers/auth.provider";
import { LoadContext } from "../providers/load.provider";

export const useProject = ({ id, ...rest }) => {
  const { startLoading, doneLoading } = useContext(LoadContext);
  const { user } = useContext(AuthContext);
  const [project, setProject] = useState(null);

  useEffect(() => {
    (async () => {
      if (!user) return;

      startLoading();
      
      try {
        const token = await user.getIdToken();

        if (!token) throw new Error('There was an issue fetching an Id Token.');

        const { data: { data } } = await getProject({ token, id });

        setProject(data);
        doneLoading();
      }
      catch (err) {
        console.error(err);
      }
    })();
  }, [user, id]);

  return project;
};