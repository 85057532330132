import { useEffect, useContext } from 'react';
import Button from './components/Button';
import Footer from './components/Footer';
import Auth from "./pages/Auth";
import Loading from './pages/Loading';
import Projects from './pages/Projects';
import { AuthContext } from './providers/auth.provider';
import { LoadContext } from './providers/load.provider';

const App = () => {
  let { user, attempted, logout } = useContext(AuthContext);
  let { loading } = useContext(LoadContext);

  // Clear query path on authentication.
  useEffect(() => {
    user && window.history.replaceState(null, '', window.location.origin)
  }, [user]);

  return <div>
    <Loading loading={!attempted && loading} branded={!user} />
    <div style={{
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      maxWidth: 685,
      margin: '0 auto',
      padding: '0px 10px'
    }}>
      <div style={{ padding: 0 }}>
        <img alt='' style={{
          height: '60px',
          minWidth: '41px',

        }} src={require('./static/img/header-logo@2x.png')} />
      </div>
      <div style={{ padding: 10 }}>
        <Button label='Log out' onClick={async () => logout()} />
      </div>
    </div>
    <div style={{ width: '100%' }}>
      {user ? <Projects /> : <Auth />}
    </div>
    <Footer />
  </div>
}

export default App;
