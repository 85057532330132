import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { parse } from 'qs';

import { sendAuthLink } from '../api/firebase.service';
import { AuthContext } from '../providers/auth.provider';
import LogInScreen from '../components/LogInScreen';

const Auth = () => {
  const { login } = useContext(AuthContext);
  const location = useLocation();
  const [email, setEmail] = useState('');

  useEffect(() => {
    const { mode, email } = parse(location.search.slice(1));

    mode === 'signIn' && login({ email, link: window.location.href });
  }, []);

  return <LogInScreen
    email={email}
    onEntry={setEmail}
    onSubmit={() => sendAuthLink({ email })}
  />
};

export default Auth;

