import React from "react";
import ProjectHeader from "../ProjectHeader";
import TaskNumbers from "../TaskNumbers";
import VarbarFrame from "../VarbarFrame";
import Button from "../Button";
import "./Project.css";

const Project = ({
  noBorder,
  name,
  onClick,
  dueOn,
  currentStatus: {
    progress,
    title,
    description,
    status,
    color,
  },
}) => {
  return (
    <div className={`project ${noBorder && 'sans-border'}`}>
      <ProjectHeader
        name={name}
        color={color}
        status={status}
      />
      <TaskNumbers dueOn={dueOn} />
      <VarbarFrame progress={progress} />
      <div className="t-summary">{title}</div>
      <div className="frame-para-1">
        <p className="para-1">
          <pre style={{ whiteSpace: 'pre-wrap' }}>
            {description}
          </pre>
        </p>
      </div>
      {!noBorder && <div className="frame-button">
        {/* <Button label='Tasks' onClick={onClick} /> */}
      </div>}
    </div>
  );
};

export default Project;