import axios from 'axios';

const _base = async ({ token, slug, method, data }) => axios.request({
  url: `${process.env.REACT_APP_NUTSHELL_API_BASE_URL}/${slug}/`,
  method,
  headers: {
    Authorization: `Bearer ${token}`
  },
  data: data?.body,
  params: data?.params
});

export const getProjects = ({ token }) => _base({
  method: 'get',
  slug: 'projects',
  token,
});

export const getProject = ({ token, id }) => _base({
  method: 'get',
  slug: `projects/${id}`,
  token,
});

export const getTask = ({ token, id }) => _base({
  method: 'get',
  slug: `tasks/${id}`,
  token,
});
