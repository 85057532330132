import React from "react";
import LogIn from "../LogIn";
import "./LogInScreen.css";

const LogInScreen = ({ onEntry, onSubmit, email }) => <div className="loginscreen screen">
  <img alt="" className="logo" src={require('../../static/img/logo@2x.png')} />
  <LogIn {...{ onEntry, onSubmit, email }} />
</div>;

export default LogInScreen;
