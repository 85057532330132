import { useReducer } from 'react';

const init = {
  loading: true,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'LOADING':
      return  {
        ...state,
        loading: true,
      };
    case 'LOADED':
      return  {
        ...state,
        loading: false,
      };
    default:
      throw new Error(`Invalid action type: ${action.type}`);
  }
};

const useLoadReducer = () => useReducer(reducer, init);

export default useLoadReducer;