import React, { useState, useEffect } from "react";
import anime from "animejs";
import "./Header.css";

const HeaderFiller = () => {
  const [ref0, setRef0] = useState(null);
  const [ref1, setRef1] = useState(null);

  useEffect(() => {
    if (!ref0 || !ref1) return;

    anime({
      targets: [ref0, ref1],
      easing: 'easeOutQuad',
      duration: 2500,
      backgroundPosition: [`100%`, '0%'],
      loop: true
    });

  }, [ref0, ref1]);
return <div className="header">
  <div className="frame-9">
    <div className="shape-rectangle"></div>
    <div className="frame-8">
    <div className="frame-project-name">
      <div ref={setRef0} className="text-company-name inter-bold-black-30px text-loading" style={{ width: 150, height: 25 }} />
      <div ref={setRef1} className="text-company-name inter-bold-black-30px text-loading" style={{ width: 120, height: 25 }} />
    </div>
    </div>
  </div>
</div>
};

export default HeaderFiller;
