import { useState } from 'react';
import { useTask } from '../../hooks/useTask.hook';
import InfoLine from "../InfoLine";
import LeftFrame from "../LeftFrame";
import RightBin from "../RightBin";
import Lottie from '../Lottie';
import "./Task.css";

const Open = ({ id }) => {
  const task = useTask({ id });

  return <div>
    <img alt="" className="line-1" src={require('../../static/img/line-1@1x.png')} />
    {!task && <Lottie src={require('../../static/lottie/lottie-noodle-group-wheel.json')} />}
    {task && <div className="frame-task-body">
      <LeftFrame
        dueDate={task.dueOn}
        completedAt={task.completedAt}
      />
      <RightBin notes={task.notes} />
    </div>}
  </div>
};

const Task = ({ id, completed, name }) => {
  const [open, setOpen] = useState(false);

  return <div className="task" onClick={() => setOpen(!open)}>
    <InfoLine
      completed={completed}
      name={name}
    />
    {open && <Open id={id} />}
  </div>;
};

export default Task;