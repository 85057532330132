import React from "react";
import { useLocation } from "react-router-dom";
import "./FrameBreadcrumbs.css";

const FrameBreadcrumbs = ({ textbreadcrumbs1, textbreadcrumbs2, onClick }) => {

  const loc = useLocation();

  return (
    <div className="frame-breadcrumbs">
      <div
        onClick={onClick}
        className={`text-breadcrumbs ${loc.pathname.slice(1) && 'link'}`}
      >
        {textbreadcrumbs1}
      </div>
      <div className="text-breadcrumbs-1">{textbreadcrumbs2}</div>
    </div>
  );
}

export default FrameBreadcrumbs;
