import React, { useState, useEffect } from "react";
import Button from "../Button";
import "./LogIn.css";

const LogIn = ({ onEntry, onSubmit, email }) => {
  const [clicked, setClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    (async () => {
      if (clicked) {
        setLoading(true);

        await onSubmit();

        setSuccess(true);
        setLoading(false);
        setClicked(false);
      }
    })();
  }, [clicked]);

  return <><div className="log-in-form">
    <div className="frame-header">
      <h1 className="text-project-name inter-bold-black-30px">Log In</h1>
    </div>
    <div className="frame-para">
      <p className="para">
        Enter your <strong>Email</strong> to receive a <strong>Verification Link</strong>.
      </p>
    </div>
    <div className="outline">
      <input
        disabled={success}
        onChange={e => onEntry(e.target.value)}
        className="email"
        name="email"
        placeholder='email'
        type='email'
        required
      />
    </div>
    <Button
      disabled={!email || success}
      label={!success ? 'Send Link' : 'Link Sent!'}
      onClick={() => setClicked(true)}
      successfulFeedback
      loading={loading}
      success={success}
    />
  </div>
    {success && <div className="email-success-blurb">
      <div className="frame-para">
        <p className="para">Please check your email inbox for a new sign-in link.</p>
      </div>
    </div>}
  </>
};

export default LogIn;
