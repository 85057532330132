import React from "react";
import FrameProjectName from "../FrameProjectName";
import "./Header.css";

const Header = ({ selectedProject, onClick, name }) => <div className="header">
  <div className="frame-9">
    <div className="shape-rectangle"></div>
    <div className="frame-8">
      <FrameProjectName
        organizationName={name}
        selectedProject={selectedProject}
        onClick={onClick}
      />
    </div>
  </div>
</div>;

export default Header;
