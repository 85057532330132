import { useContext, useEffect, useState } from "react";
import { getProjects } from "../api/nutshell.service";
import { AuthContext } from "../providers/auth.provider";
import { useLoading } from "./useLoading.hook";

export const useProjects = () => {
  const { user } = useContext(AuthContext);
  const [projects, setProjects] = useState([]);
  const [orgName, setOrgName] = useState([]);

  const loadProjects = useLoading(async () => {
    if (!user) return;

    try {
      const token = await user.getIdToken();

      if (!token) throw new Error('There was an issue fetching an Id Token.');

      const { data: { data } } = await getProjects({ token });

      setOrgName(data[0].name);
      setProjects(data[0].projects);
    }
    catch (err) {
      console.error(err);
    }
  });

  useEffect(() => {
    if (!projects.length) loadProjects();
  }, [user]);

  return { orgName, projects };
};